<template>
  <div class="preview-actionType">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title"> {{ formData.id }} </h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>

    <CTabs class="zq--tabs">
      <CTab title="Message">
        <DraftDetails></DraftDetails>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import DraftDetails from './details/DraftDetails';
import {mapGetters} from "vuex";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {messaging} from "@/config/descriptions/messaging"


export default {
  components: {
    DraftDetails,
    IconWithTooltip
  },
  data() {
    return {
      descriptions: {
        ...messaging.draft.preview
      },
      formData: {
        id: '',
      },
    };
  },
  computed: {
    ...mapGetters('messages', ['singleMessage']),
  },
  watch: {
    singleMessage: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
}
</script>

<style lang="scss">
.preview-actionType {
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>